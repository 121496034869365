<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="main">
      <div>
        <h4>变更流程</h4>
        <div class="w75p mga">
          <el-steps :active="0">
            <!-- wait / process / finish / error / success -->
            <el-step title="合同变更" status="finish" icon="el-icon-time"></el-step>
            <el-step title="待乙方确认" icon="el-icon-question"></el-step>
            <el-step title="加盟商生效" icon="el-icon-question"></el-step>
          </el-steps>
        </div>
      </div>
      <div class="pdb30">
        <h4>加盟商信息</h4>
        <div class="flex_center pdt10">
          <!-- 甲方 -->
          <div class="w50p">
            <div class="flex_start">
              <span class="w110 fontC3 font14">甲方</span>
              <span class="flex1 fontC2 font14 pdl30">{{ detailData.parentCompanyName }}</span>
            </div>
            <div class="flex_start mgt20">
              <span class="w110 fontC3 font14">加盟商等级</span>
              <span class="flex1 fontC2 font14 pdl30">
                {{ detailData.agencyLevel + 1 }}级加盟商
              </span>
            </div>
            <div class="flex_start mgt20">
              <span class="w110 fontC3 font14">最大可分配乙方营收比例(%)</span>
              <span class="flex1 fontC2 font14 pdl30">{{ maxRate / 100 }}</span>
            </div>
            <div class="flex_start mgt20">
              <span class="w110 fontC3 font14">变更后乙方营收比例(%)</span>
              <div class="pdl30 flex1">
                <el-input
                  v-model="projectForm.rate"
                  placeholder="请输入百分比"
                  type="number"
                  class="w300"
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </div>
            <div class="flex_start mgt20">
              <span class="w110 fontC3 font14">是否需要确认</span>
              <div class="flex1 fontC2 font14 pdl30">
                <el-radio-group v-model="projectForm.needConfirm">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <!-- 乙方 -->
          <div class="w50p">
            <div class="flex_start">
              <span class="w110 fontC3 font14">乙方</span>
              <span class="flex1 fontC2 font14 pdl30">{{ detailData.companyName }}</span>
            </div>
            <div class="flex_start mgt20">
              <span class="w110 fontC3 font14">乙方营收比例(%)</span>
              <span class="flex1 fontC2 font14 pdl30">{{ detailData.rate / 100 }}%</span>
            </div>
            <div class="flex_start_start mgt20">
              <span class="w110 fontC3 font14 pdt20">加盟合同</span>
              <div class="flex1">
                <le-upload-file
                  ref="pic"
                  v-model="detailData.contractFile"
                  label="加盟商合同"
                  @input="uploadFlie"
                ></le-upload-file>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="a-line-t-e0">
        <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
        <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" @click="cancel">
          &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyId: "", //
      maxRate: 0,
      detailData: "",
      projectForm: {
        currentCompanyId: "",
        needConfirm: 0, //是否确认
        agencyId: "", //乙方合同id
        rate: 0, //营收比例
        contractFile: "", //合同文件
      },
    };
  },
  created() {
    this.companyId = this.$route.query.code;
    this.projectForm.currentCompanyId = this.companyId;
    this.getSigningAgencyDetail();
  },
  methods: {
    submit() {
      if (!this.projectForm["rate"]) {
        this.$message.error("请填写变更后乙方营收比例!");
        return;
      }
      if (this.projectForm["rate"] < 0) {
        this.$message.error("乙方营收比例不得小于0!");
        return;
      }
      if (this.projectForm["rate"] > this.maxRate) {
        this.$message.error("乙方营收比例不得大于可分配比例!");
        return;
      }
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.changeAgency,
          params: {
            ...this.projectForm,
            rate: parseInt(this.projectForm.rate) * 100,
            agencyId: parseInt(this.projectForm.agencyId),
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message.success("变更合同成功!");
            setTimeout(() => {
              this.$router.push({
                path: "/franchisee/franchisee-contract?code=" + this.companyId,
              });
            }, 1500);
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    //获取合同详情
    getSigningAgencyDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getSigningAgencyDetail,
          params: {
            agencyCompanyId: parseInt(this.companyId),
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            if (res.result.data) {
              this.detailData = res.result.data;
              this.projectForm.agencyId = this.detailData.id;
              this.getAgencyMaxRate(this.detailData.parentCompanyId);
            }
          }
        });
    },
    //获取最大营收比例
    getAgencyMaxRate(code) {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getAgencyMaxRate,
          params: { companyId: code },
        })
        .then((res) => {
          this.maxRate = res.result.data;
        });
    },
    //上传文件
    uploadFlie(val) {
      this.projectForm.contractFile = val;
    },
    //退出
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 100%;
  overflow: auto;
}
</style>
